import styled from 'styled-components';

import {
  COLOR_BASE_BRAND_BLACK,
  COLOR_BASE_BRAND_WHITE,
  SPACING_XL_XS,
  SPACING_XXXL_M,
  SPACING_XXXL_L,
  SPACING_XXL_L,
  SPACING_XXXL_XL,
  BREAKPOINT_M,
  BREAKPOINT_L,
  BREAKPOINT_XL,
} from '@audi/audi-ui-design-tokens';

export const Container = styled.div`
  background-color: ${(props) =>
    props.theme === 'light' ? COLOR_BASE_BRAND_WHITE : COLOR_BASE_BRAND_BLACK};
  padding: ${SPACING_XXL_L}px ${SPACING_XL_XS}px;
  overflow-x: scroll;

  table {
    th {
      border-color: ${(props) =>
        props.theme === 'dark' ? `grey !important` : `${COLOR_BASE_BRAND_BLACK} !important`};
    }
    tbody {
      tr:first-child th,
      tr:first-child td {
        border-top: ${(props) =>
          props.theme === 'dark'
            ? `1px solid grey !important`
            : `1px solid ${COLOR_BASE_BRAND_BLACK} !important`};
      }
    }
    sup {
      text-decoration: none;
      color: inherit;
      a {
        text-decoration: none;
        color: inherit;
        z-index: 99999;
      }
    }
  }

  @media (min-width: ${BREAKPOINT_M}px) {
    padding: ${SPACING_XXXL_M}px ${SPACING_XXL_L}px;
    overflow-x: unset;

    table {
      width: 100%;
    }
  }

  @media (min-width: ${BREAKPOINT_L}px) {
    padding: ${SPACING_XXXL_L}px;
  }

  @media (min-width: ${BREAKPOINT_XL}px) {
    padding: ${SPACING_XXXL_XL}px 96px;
  }

  td > span {
    border-spacing: 0;
  }

  td > span > em {
    font-style: italic;
  }
`;
