import React from 'react';
import { AudiFootnoteReferenceServiceInterfaceV3 } from '@oneaudi/footnote-reference-service';
import { Layout } from '@oneaudi/unified-web-components';
import { FootnoteContextProvider } from '@oneaudi/feature-app-utils';
import { convertToSanitzedString } from './editorialTableUtils';
import { reconstructWithDesignComponents } from '../reconstructWithDesignComponents/reconstructWithDesignComponents';
import { Container } from './style';

export interface EditorialTableTableContent {
  bodyTable: string;
  theme: string;
}

export interface Props {
  content: EditorialTableTableContent;
  footnoteReferenceService: AudiFootnoteReferenceServiceInterfaceV3;
}

export const EditorialTable = ({ content, footnoteReferenceService }: Props) => {
  const { bodyTable, theme } = content;
  const sanitzizedString = convertToSanitzedString(bodyTable);

  const dataForTable = reconstructWithDesignComponents(sanitzizedString);
  return (
    <FootnoteContextProvider footnoteReferenceService={footnoteReferenceService || {}}>
      {bodyTable && (
        <Container data-testid="container" theme={theme}>
          <Layout direction="column">{dataForTable}</Layout>
        </Container>
      )}
    </FootnoteContextProvider>
  );
};

export default EditorialTable;
