/**
 * This is the entry point for Feature Hub App integration
 */

import React from 'react';
import {
  FeatureAppDefinition,
  FeatureAppEnvironment,
  FeatureServiceProviderDefinition,
  FeatureServices,
} from '@feature-hub/core';
import { ReactFeatureApp } from '@feature-hub/react';
import type { Logger } from '@feature-hub/logger';
import type { ContentServiceV1 as ContentService } from '@volkswagen-onehub/audi-content-service';
import {
  AudiFootnoteReferenceServiceInterface,
  AudiFootnoteReferenceServiceInterfaceV3,
  AudiFootnoteRefernceServiceScopeManagerInterfaceV3,
} from '@oneaudi/footnote-reference-service';
import type { TrackingServiceV2 as TrackingService } from '@volkswagen-onehub/audi-tracking-service';
import { SerializedStateManagerV1 } from '@feature-hub/serialized-state-manager';
import { AsyncSsrManagerV1 } from '@feature-hub/async-ssr-manager';
import type { GfaLocaleServiceV1 } from '@volkswagen-onehub/gfa-locale-service';
import { GfaServiceConfigProviderV1 } from '@volkswagen-onehub/gfa-service-config-provider';
import App from './FeatureApp';

export interface AppContent {
  __type?: 'default';
  theme: string;
  bodyTable: string;
}
export interface FeatureServiceDependencies extends FeatureServices {
  readonly 's2:logger'?: Logger;
  readonly 'gfa:service-config-provider': GfaServiceConfigProviderV1;
  readonly 'gfa:locale-service': GfaLocaleServiceV1;
  readonly 'audi-content-service'?: ContentService;
  readonly 'audi-tracking-service'?: TrackingService;
  readonly 's2:async-ssr-manager'?: AsyncSsrManagerV1;
  readonly 's2:serialized-state-manager'?: SerializedStateManagerV1;
  readonly 'audi-footnote-reference-service'?: FeatureServiceProviderDefinition<AudiFootnoteReferenceServiceInterface>;
}

const featureAppDefinition: FeatureAppDefinition<ReactFeatureApp, FeatureServiceDependencies> = {
  dependencies: {
    featureServices: {
      's2:logger': '^1.0.0',
      'gfa:locale-service': '^1.0.0',
    },
    externals: {
      react: '*',
      'styled-components': '*',
      '@oneaudi/unified-web-common': '*',
    },
  },
  optionalDependencies: {
    featureServices: {
      'audi-content-service': '^1.0.0',
      'audi-tracking-service': '^2.0.0',
      's2:async-ssr-manager': '^1.0.0',
      's2:serialized-state-manager': '^1.0.0',
      'audi-footnote-reference-service': '^3.0.0',
    },
  },

  create: ({ featureServices }: FeatureAppEnvironment<FeatureServiceDependencies, void>) => {
    const loggerService = featureServices['s2:logger'];
    loggerService?.info('Feature App created.');
    const contentService = featureServices['audi-content-service'];
    const configProvider = featureServices['gfa:service-config-provider'];
    const trackingService = featureServices['audi-tracking-service'] as TrackingService;

    // SSR
    // --------------------------------------------------------------------
    const asyncSSRManager = featureServices['s2:async-ssr-manager'];
    const serializedStateManager = featureServices['s2:serialized-state-manager'];

    const providerfootnoteService = featureServices[
      'audi-footnote-reference-service'
    ] as unknown as AudiFootnoteRefernceServiceScopeManagerInterfaceV3;

    const footnoteReferenceService: AudiFootnoteReferenceServiceInterfaceV3 =
      providerfootnoteService?.getDefaultScopeRefService();
    // on the server
    if (asyncSSRManager) {
      asyncSSRManager.scheduleRerender(
        (async () => {
          const content = (await contentService?.getContent()) as AppContent;
          serializedStateManager?.register(() => JSON.stringify(content));
        })(),
      );
    }

    return {
      render: () => {
        return (
          <div data-fefa-custom-id={footnoteReferenceService?.getConsumerId()}>
            <App
              configProvider={configProvider}
              contentService={contentService}
              trackingService={trackingService}
              footnoteReferenceService={footnoteReferenceService}
            />
          </div>
        );
      },
    };
  },
};

export default featureAppDefinition;
