/**
 * This is the starting point of your application.
 * Feature Hub Apps will use this file to bootstrap the app.
 */

import React, { useEffect, useState } from 'react';
import { GfaServiceConfigProviderV1 } from '@volkswagen-onehub/gfa-service-config-provider';
import {
  ContentServiceV1 as ContentService,
  ContentChangeListener,
} from '@volkswagen-onehub/audi-content-service';
import { TrackingServiceV2 } from '@volkswagen-onehub/audi-tracking-service';
import { createTrackingService } from '@volkswagen-onehub/oneaudi-os-adapter-tracking';
import { AudiFootnoteReferenceServiceInterfaceV3 } from '@oneaudi/footnote-reference-service';
import { ThemeProvider } from '@oneaudi/unified-web-common';
import { ThemeName } from '@oneaudi/unified-web-common/dist/cjs/components/theme-provider/theme';
import EditorialTable, {
  EditorialTableTableContent,
} from './components/EditorialTable/EditorialTable';

export interface AppContent {
  __type?: 'default';
  fields: {
    theme: string;
    bodyTable: string;
  };
}

interface FeatureAppProps {
  configProvider?: GfaServiceConfigProviderV1;
  contentService?: ContentService;
  trackingService?: TrackingServiceV2;
  content?: EditorialTableTableContent;
  footnoteReferenceService: AudiFootnoteReferenceServiceInterfaceV3;
}

const FeatureApp: React.FC<FeatureAppProps> = ({ ...inherited }: FeatureAppProps) => {
  let trackingService = {} as TrackingServiceV2;

  if (typeof window !== 'undefined') {
    trackingService = inherited.trackingService || createTrackingService(__FEATURE_APP_NAME__);
  }

  const { contentService, footnoteReferenceService } = inherited;

  const [content, setContent] = useState(contentService?.getContent() as AppContent);

  useEffect(() => {
    trackingService.featureAppName = __FEATURE_APP_NAME__;
    const readyEvent = {
      event: {
        attributes: {
          componentName: __FEATURE_APP_NAME__,
          elementName: '',
          label: '',
          clickID: '',
          pos: '',
          targetURL: '',
          value: '',
        },
        eventInfo: {
          eventAction: 'feature_app_ready',
          eventName: `${__FEATURE_APP_NAME__} - Feature App ready`,
        },
      },
    };
    trackingService.track(readyEvent);
  }, []);

  contentService?.onContentChange(setContent as ContentChangeListener);
  const { theme } = content.fields;

  return (
    <ThemeProvider theme={{ iconBasePath: '/icons', name: theme as ThemeName }}>
      <EditorialTable
        content={content.fields}
        footnoteReferenceService={footnoteReferenceService}
      />
    </ThemeProvider>
  );
};

export default FeatureApp;
